import { Injectable } from '@angular/core';

declare global {
    interface Window {
        fbq: any;
    }
}

interface IFbTrackOptions {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    content_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    content_category?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    content_type?: string;
    value?: number;
    currency?: string;
}

@Injectable({ providedIn: 'root' })
export class FacebookService {
    public trackEvent(
        trackType: string,
        trackOptions?: IFbTrackOptions
    ): void {
        if (!window.fbq) {
            return;
        }

        window.fbq('track', trackType, trackOptions || { });
    }
}
