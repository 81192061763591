import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';

import { ICart, ICartItem } from '../interfaces';

import { ApiService } from './api.service';
import { UtilsService } from './utils.service';

declare let dataLayer: any[];

@Injectable({ providedIn: 'root' })
export class GlobalCartService {
    public $cart: BehaviorSubject<ICart> = new BehaviorSubject(null);
    public $cartSubscription: BehaviorSubject<ICart> = new BehaviorSubject(null);
    public cartOverDisplayed = false;
    public subscriptionStarterMagazineSelected: string;

    constructor(
        private readonly apiService: ApiService,
        private readonly utilsService: UtilsService,
        private readonly router: Router,
    ) {
    }

    // eslint-disable-next-line max-len
    public addItemInCart(magazineId: number, magazineVersionId: number, subscription?: boolean, quantity?: number, couponCode?: string): Observable<any> {
        if (!this.utilsService.onBrowser()) {
            return;
        }

        const cartHash: string = !subscription ? localStorage.getItem('cart-hash') || '' : '';

        return this.apiService.postRequest(
            cartHash ? `shopping-cart/${cartHash}/item` : 'shopping-cart/item',
            {
                magazineId: magazineId,
                magazineVariationId: magazineVersionId,
                magazineQty: quantity || 1,
                offerCode: couponCode, // TODO : Besoin de l'ajouter dans la dto côté api ?
            }
        ).pipe(
            take(1),
            tap((cart: { cartHash: string; cart: ICart }) => {
                this.createDataLayerToGA(cart.cart, magazineVersionId, magazineId);

                if (subscription) {
                    localStorage.setItem('subscription-cart-hash', cart.cartHash);
                    this.$cartSubscription.next(cart.cart);
                    this.router.navigate(['/panier/validation']).then();
                    return;
                }

                if (!quantity) { // Open when new product is add but not when the number of a product already in the cart is change
                    this.cartOverDisplayed = true; // Open Cart on overlay
                }

                if (!localStorage.getItem('cart-hash')) {
                    localStorage.setItem('cart-hash', cart.cartHash);
                }

                this.$cart.next(cart.cart);
            })
        );
    }

    public getCart(forceCartGet: any = false): Observable<ICart> {
        if (!this.utilsService.onBrowser()) {
            return;
        }

        const cartHash: string = forceCartGet ?
            localStorage.getItem('cart-hash') :
            localStorage.getItem('subscription-cart-hash') || localStorage.getItem('cart-hash');

        if (!cartHash) {
            return of(null);
        }

        return this.apiService.getRequest(`shopping-cart/${cartHash}`)
            .pipe(
                take(1),
                tap((cart: ICart) => {
                    if (localStorage.getItem('subscription-cart-hash') && !forceCartGet) {
                        this.$cartSubscription.next(cart);
                        return;
                    }

                    this.$cart.next(cart);
                })
            );
    }

    // return current cart display in cart validation steps
    public combineCart(): Observable<ICart> {
        return combineLatest([this.$cartSubscription, this.$cart])
            .pipe(
                map((carts: ICart[]) => carts[0] || carts[1]),
            );
    }

    public removeItemInCart(cartItemId: number): void {
        if (!this.utilsService.onBrowser()) {
            return;
        }

        const cartHash: string = localStorage.getItem('cart-hash');
        this.apiService.deleteRequest(`shopping-cart/${cartHash}/item/${cartItemId}`)
            .subscribe((cart: ICart) => {
                this.$cart.next(cart);

                if (!cart.items) {
                    this.removeStoredCart();
                }
            });
    }

    public removeCart(): void {
        if (!this.utilsService.onBrowser()) {
            return;
        }

        const cartHash: string = localStorage.getItem('subscription-cart-hash') || localStorage.getItem('cart-hash');
        this.apiService.deleteRequest(`shopping-cart/${cartHash}`)
            .subscribe(() => {
                this.removeStoredCart();
            });
    }

    public removeStoredCart(): void {
        if (localStorage.getItem('subscription-cart-hash')) {
            localStorage.removeItem('subscription-cart-hash');
            this.$cartSubscription.next(null);
            return;
        }

        localStorage.removeItem('cart-hash');
        this.$cart.next(null);
    }

    private createDataLayerToGA(cartDetail: any, magazineVersionId: number, magazineId: number): void {
        cartDetail.items.forEach((item: ICartItem) => {
            // For magazine ou for subscription item
            if (item.versionId === magazineVersionId || (!item.versionId && item.id === magazineId)) {
                dataLayer.push({
                    event: 'success-add-to-cart',
                    ecommerce: {
                        currency: 'EUR',
                        value: item.price.toString().replace(',', '.'),
                        items: [{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            item_id: item.versionId || item.id,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            item_name: item.isSubscriptionProduct ? `${item.brandName} - ${item.name}` : `${item.name}`,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            item_brand: item.brandName,
                        }],
                    }
                });

                return;
            }
        });
    }
}
