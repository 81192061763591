import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UtilsService {
    public globalLoaderDisplay = true;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
    }

    /**
     * Check if the width of the device is mobile size or desktop size
     * Return True when the device size is mobile
     */
    public onMobile(): boolean {
        return this.onBrowser() && window.innerWidth <= 950;
    }

    /**
     * Check if the app is running on browser side and not server side
     * Return True when the application is running on browser
     */
    public onBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public scrollToTop(): void {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}
