import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';

import { environment } from '../../../environments/environment';

import { UtilsService } from './utils.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private userToken: string = null;

    constructor(
        private readonly utilsService: UtilsService,
        private readonly apiService: ApiService,
    ) {
    }

    public getAuthToken(): string {
        if (!this.utilsService.onBrowser()) {
            return '';
        }

        if (this.userToken) {
            return this.userToken;
        }

        this.userToken = localStorage.getItem('user-access-token') || '';
        this.setUserIdToSentryError(this.userToken);
        return this.userToken;
    }

    public getCookie(cookieKey: string): string {
        if (!this.utilsService.onBrowser()) {
            return '';
        }

        const cookie: string[] = document.cookie.split('; ').filter((cookie: string ) => {
            return cookie.search(cookieKey) !== -1;
        });

        return cookie?.length > 0 ? cookie[0] : '';
    }

    public setUserCookieAndToken(token: string, cookies: string): void {
        this.setUserCookie(cookies);
        localStorage.setItem('user-access-token', token);
        this.setUserIdToSentryError(token);
        this.userToken = token;
    }

    public setUserCookie(cookies: string): void {
        const cookiesList: string[] = cookies.split('|');
        for (const cookie of cookiesList) {
            document.cookie = cookie;
        }
    }

    public removeAuthCookies(): void {
        localStorage.removeItem('user-access-token');
        document.cookie = 'check-user-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        if (environment.sentry) {
            Sentry.setUser({ id: null });
        }
        this.userToken = null;
    }

    public getUserInformations(): Observable<any> {
        return this.apiService.getRequest(
            'users',
            {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'user-access-token': this.getAuthToken(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'user-access-cookie': this.getCookie('check-user-access-token')
            },
        );
    }

    private setUserIdToSentryError(token: string): void {
        if (environment.sentry && token) {
            const userId: string = this.parseJwt(token)?.data?.user?.id;

            if (userId) {
                Sentry.setUser({ id: userId });
            }
        }
    }

    private parseJwt(token: string): any {
        const base64Url = token.split('.')[1];


        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c: string) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
