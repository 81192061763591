import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
    private websiteOriginHeader = {
        'siteOrigin': environment.frontUrl,
    };

    constructor(private readonly http: HttpClient) {
    }

    /**
     * Do a GET call API to the api url set in the env file
     * @param url (collection and parameters)
     * @param headers data
     * @param responseType data
     * @Return Observable with response of the request
     */
    public getRequest(url: string, headers: any = {}, responseType: any = 'json'): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/${url}`,
            {
                headers: new HttpHeaders(
                    Object.assign(headers, this.websiteOriginHeader)
                ),
                responseType
            }
        );
    }

    public postRequest(url: string, body: any, headers: any = {}, otherOptions: unknown = {}): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/${url}`,
            body,
            Object.assign({
                headers: new HttpHeaders(
                    Object.assign(
                        {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            'Content-Type': 'application/json'
                        },
                        headers,
                        this.websiteOriginHeader,
                    )
                )
            }, otherOptions)
        );
    }

    public deleteRequest(url: string, headers: any = {}): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/${url}`,
            {
                headers: new HttpHeaders(
                    Object.assign(headers, this.websiteOriginHeader)
                )
            }
        );
    }

    public putRequest(url: string, body: any, headers: any = {}): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/${url}`,
            body,
            {
                headers: new HttpHeaders(
                    Object.assign(
                        {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            'Content-Type': 'application/json'
                        },
                        headers,
                        this.websiteOriginHeader,
                    )
                )
            }
        );
    }

    public apiRequest(
        type: 'get' | 'post' | 'put' | 'delete',
        url: string,
        headers: any = { },
        otherOptions: any = { },
        body?: any,
    ): Observable<any> {
        if (type === 'get' || type === 'delete') {
            // @ts-ignore
            return this.http[type](
                `${environment.apiUrl}/${url}`,
                Object.assign({
                    headers: new HttpHeaders(
                        Object.assign(
                            {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                'Content-Type': 'application/json'
                            },
                            headers,
                            this.websiteOriginHeader,
                        )
                    )
                }, otherOptions)
            );
        }

        return this.http[type](
            `${environment.apiUrl}/${url}`,
            body,
            Object.assign({
                headers: new HttpHeaders(
                    Object.assign(
                        {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            'Content-Type': 'application/json'
                        },
                        headers,
                        this.websiteOriginHeader,
                    )
                )
            }, otherOptions)
        );
    }
}
